import { Facility } from "js/pages/map/types";

export default class FacilityHelper {
  public static isMedical?(facility: Facility): boolean {
    return facility.facility_class_type == "facility_medical";
  }

  public static isJigyosho?(facility: Facility): boolean {
    return facility.facility_class_type == "facility_jigyosho";
  }

  public static showEditRequest?(facility: Facility): boolean {
    return FacilityHelper.isMedical(facility);
  }

  public static showDeleteRequest?(facility: Facility): boolean {
    return FacilityHelper.isMedical(facility);
  }
}
