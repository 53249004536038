import { Controller } from "@hotwired/stimulus";

export default class FormSubmitController extends Controller {
  private form: HTMLFormElement;

  connect() {
    this.form = this.element.closest("form");
    this.element.addEventListener("change", this.inputChange);
  }

  inputChange = () => {
    this.form.requestSubmit();
  };

  disconnect() {
    this.element.removeEventListener("change", this.inputChange);
  }
}
