import React, { MouseEvent, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import clsx from "clsx";

export type SlideProps = {
  children: React.ReactNode;
  footer: React.ReactNode;
  onClose: () => void;
  open?: boolean | null;
  className?: string;
};

export const Slide = ({ open = null, className, ...props }: SlideProps) => {
  const css = clsx("c-slide__main", className);

  const onMainClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const [frame, setFrame] = useState<boolean>(false);

  const [styles, api] = useSpring(() => ({
    marginTop: window.innerHeight,
    config: { duration: 300 },
  }));

  useEffect(() => {
    if (open == true) {
      setFrame(true);
    }
    void api.start({
      marginTop: open ? 40 : window.innerHeight,
      onRest: () => {
        if (open == false) {
          setFrame(open);
        }
      },
    });
  }, [open]);

  return (
    open != null && (
      <div
        className="c-slide"
        onClick={props.onClose}
        style={{ display: frame ? "block" : "none" }}
      >
        <animated.div className={css} onClick={onMainClick} style={styles}>
          <i className="c-slide__closer" onClick={props.onClose} />
          <div className="c-slide__body">
            <div className="c-slide__inner">{props.children}</div>
          </div>
          <div className="c-slide__footer">{props.footer}</div>
        </animated.div>
      </div>
    )
  );
};
