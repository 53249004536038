export default class ElementHelper {
  private static readonly hideCss = "u-d-none";

  public static show(element: HTMLElement): void {
    if (!element) {
      return;
    }
    element.style.display = "";
  }

  public static hide(element: HTMLElement): void {
    if (!element) {
      return;
    }
    element.style.display = "none";
  }

  // 表示する
  public static fadeIn($element: JQuery, duration = 300): void {
    $element.css({ opacity: 0 }).removeClass(this.hideCss).animate(
      {
        opacity: 1,
      },
      duration,
    );
  }

  // 非表示する
  public static fadeOut($element: JQuery, duration = 300): void {
    $element.css({ opacity: 1 }).animate(
      {
        opacity: 0,
      },
      duration,
      () => {
        $element.addClass(this.hideCss);
      },
    );
  }
}
