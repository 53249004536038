import axios, { AxiosError, AxiosResponse } from "axios";

export default class AxiosInterceptors {
  public static addResponse(): void {
    axios.interceptors.response.use(
      (response) => {
        return response.data as AxiosResponse<unknown, unknown>;
      },
      (error: AxiosError) => {
        if (error.response) {
          if (error.response.status == 401 || error.response.status == 403) {
            window.location.href = "/r/users/signin";
          }
        }
      },
    );
  }
}
