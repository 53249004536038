import { Controller } from "@hotwired/stimulus";

type RadioTarget = {
  key: string;
  target: JQuery;
};

export default class extends Controller {
  static values = {
    showValues: String,
  };

  private $element: JQuery<Element>;
  readonly showValuesValue!: string;
  private $radios: JQuery;
  private toggleTargets: RadioTarget[];

  connect() {
    this.$element = $(this.element);
    this.$radios = this.$element.find(".js-radio");
    this.$radios.on("change", this.toggleDetail);
    const values = JSON.parse(this.showValuesValue) as {
      [key: string]: string;
    };
    this.toggleTargets = Object.keys(values).map((key) => {
      return { key: key, target: $(`#${values[key]}`) };
    });

    this.toggleDetail();
  }

  toggleDetail = () => {
    const currentValue = this.$radios.filter(":checked").val();
    this.toggleTargets.forEach((tar) => {
      tar.target.hide();
    });

    if (currentValue) {
      const showTarget = this.toggleTargets.find((tar) => {
        return tar.key == currentValue.toString();
      });
      showTarget?.target.show();
    }
  };

  disconnect() {
    this.$radios.off("change", this.toggleDetail);
  }
}
