import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  private $element: JQuery<Element>;
  private $checkBox: JQuery;
  private $target: JQuery;

  connect() {
    this.$element = $(this.element);
    this.$checkBox = this.$element.find(".js-checkbox");
    this.$target = this.$element.find(".js-toggle-target");
    this.$checkBox.on("change", this.toggleDetail);
    this.toggleDetail();
  }

  toggleDetail = () => {
    if (this.$checkBox.prop("checked")) {
      this.$target.show();
    } else {
      this.$target.hide();
    }
  };

  disconnect() {
    this.$checkBox.off("change", this.toggleDetail);
  }
}
