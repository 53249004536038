import React from "react";

type Props = {
  value: string;
  label: string;
};

export const ConditionDetail = ({ value, label }: Props) => {
  if (!value) {
    return null;
  }

  return (
    <div className="o-flex o-flex--xs">
      <span className="c-facility-print__top__title">{label}：</span>
      <span>{value}</span>
    </div>
  );
};
