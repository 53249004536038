import React from "react";
import clsx from "clsx";

export type SearchBoxProps = {
  name: string;
  placeholder?: string;
  value?: string;
  className?: string;
  onChange?: () => void;
  onClick?: () => void;
  readOnly?: boolean;
};

export const SearchBox = (props: SearchBoxProps) => {
  const css = clsx("c-search", props.className);

  return (
    <div className={css} onClick={props.onClick}>
      <input
        type="text"
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        readOnly={props.readOnly}
      />
    </div>
  );
};
