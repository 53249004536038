import { MutableRefObject, useEffect } from "react";
import { AnyFunc } from "js/types";

export const useClickOutside = (
  open: boolean,
  ref: MutableRefObject<HTMLElement>,
  callback: AnyFunc,
) => {
  useEffect(() => {
    const handleClick = (event: Event) => {
      const slide = ref.current;
      if (slide && !slide.contains(event.target as Node)) {
        callback();
      }
    };
    if (open) {
      document.addEventListener(`click`, handleClick);
    }
    return () => document.removeEventListener(`click`, handleClick);
  });
};
