import { Controller } from "@hotwired/stimulus";

type SelectFile = {
  file: File;
  index: number;
};

// TODO: reactで書き直す
export default class MessageFilesController extends Controller {
  static targets = ["selectFiles", "uploadFiles", "labels"];

  selectFilesTarget: HTMLInputElement;
  uploadFilesTarget: HTMLInputElement;
  labelsTarget: HTMLInputElement;
  $labels: JQuery;
  files: SelectFile[] = [];

  connect() {
    this.selectFilesTarget.addEventListener("change", this.onFileChange);
    this.$labels = $(this.labelsTarget);
  }

  onFileChange = () => {
    const files = this.selectFilesTarget.files;
    this.onAddFile(files);
  };

  private onAddFile(files: FileList): void {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileIndex = this.files.length + 1;
      this.files.push({ file: file, index: fileIndex });
      // add label
      const fileEle = $(
        `<div class="p-msg-file" data-index="${fileIndex}">${file.name}<i class="p-msg-file__del js-del"></i></div>`,
      );
      fileEle.find(".js-del").on("click", (e) => {
        const $fileLabel = $(e.target).parent();
        this.onDeleteFile($fileLabel);
      });
      this.$labels.append(fileEle);
    }
    this.setUploadFiles();
  }

  private onDeleteFile($fileLabel: JQuery): void {
    const index = parseInt($fileLabel.data("index") as string);
    $fileLabel.remove();
    this.files = this.files.filter((fi) => {
      return fi.index != index;
    });
    this.setUploadFiles();
  }

  private setUploadFiles(): void {
    const dt = new DataTransfer();
    dt.items.clear();
    for (const file of this.files) {
      dt.items.add(file.file);
    }
    this.uploadFilesTarget.files = dt.files;
  }

  disconnect() {
    this.selectFilesTarget.removeEventListener("change", this.onFileChange);
  }
}
