import React from "react";
import clsx from "clsx";

const nameList = {
  info: "c-icon-info",
  "up-down": "c-icon-up-down",
  star: "c-icon-star",
  "star-gray": "c-icon-star-gray",
  "star-green": "c-icon-star-green",
  print: "c-icon-print",
  "arrow-left": "c-icon-arrow-left",
  "map-pin-green": "c-icon-map-pin-green",
};

const sizes = {
  md: "",
  lg: "c-icon--lg",
};

export type IconProps = {
  name: keyof typeof nameList;
  size?: keyof typeof sizes;
  className?: string;
};

export const Icon = ({ name, size = "md", className }: IconProps) => {
  const css = clsx(`c-icon-${name}`, sizes[size], className);

  return <i className={css} />;
};
