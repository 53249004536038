export const setGaTag = () => {
  const gaCode = document.body.dataset.gaCode;
  if (!gaCode) {
    return;
  }

  /* eslint-disable */
  document.addEventListener(
    "turbo:load",
    function () {
      window.dataLayer = window.dataLayer || [];

      function gtag(name: string, value: any) {
        window.dataLayer.push(arguments);
      }

      gtag("js", new Date());

      gtag("config", gaCode);
    },
    false,
  );
};
