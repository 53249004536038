import { Controller } from "@hotwired/stimulus";
import React, { lazy, Suspense } from "react";
import { Root, createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Loader } from "js/components/Elements/Loader";
import { CommonServerSetting } from "js/features/setting/CommonServerSetting";
import AppError from "js/components/AppError";

export default class extends Controller {
  static targets = ["name"];

  private root: Root;

  connect() {
    const setting = CommonServerSetting.getSetting();
    if (setting.sentry_dsn) {
      Sentry.init({
        dsn: setting.sentry_dsn,
        allowUrls: setting.sentry_whitelist_urls,
        environment: setting.environment,
      });
    }
    const container = this.element;
    this.root = createRoot(container);
    const Map = lazy(
      () => import(/* webpackChunkName: 'pages/map' */ "js/pages/map/index"),
    );
    const Room = lazy(
      () => import(/* webpackChunkName: 'pages/room' */ "js/pages/room/index"),
    );
    this.root.render(
      <RecoilRoot>
        <BrowserRouter basename="/r">
          <Suspense fallback={<Loader show={true} />}>
            <Sentry.ErrorBoundary fallback={AppError}>
              <Routes>
                <Route path="map" element={<Map />} />
                <Route
                  path="patients/:patient_id/facilities/map"
                  element={<Map />}
                />
                <Route path="rooms" element={<Room />} />
              </Routes>
            </Sentry.ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </RecoilRoot>,
    );
  }

  disconnect() {
    this.root.unmount();
    this.root = null;
  }
}
