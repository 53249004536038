import { Controller } from "@hotwired/stimulus";
import ElementHelper from "js/util/ElementHelper";

export default class NotificationController extends Controller {
  static targets = ["notification"];

  notificationTarget: HTMLDivElement;
  timeoutId: number;

  connect() {
    this.reserveFadeOut();
  }

  reserveFadeOut(delay = 3000): void {
    const hideNotification = (): void => {
      ElementHelper.hide(this.notificationTarget);
    };
    this.timeoutId = window.setTimeout(hideNotification, delay);
  }

  disconnect() {
    clearTimeout(this.timeoutId);
  }
}
