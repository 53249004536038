import { Controller } from "@hotwired/stimulus";

type Template = {
  type_id: string;
  body: string;
};

export default class MessageFormController extends Controller {
  static values = {
    templates: String,
  };

  readonly templatesValue!: string;
  private $element: JQuery<Element>;
  private $typeRadios: JQuery;
  private $messageField: JQuery;
  private $templateSelect: JQuery;
  private templates: Template[];

  connect() {
    this.$element = $(this.element);
    this.$typeRadios = this.$element
      .find(".js-types")
      .find('input[type="radio"]');
    this.$messageField = this.$element.find(".js-message");
    this.$templateSelect = this.$element.find(".js-template-select");

    this.$typeRadios.on("change", this.onTypeChange);
    this.$templateSelect.on("change", this.onTemplateChange);
    this.templates = JSON.parse(this.templatesValue) as Template[];
    this.onTypeChange();
  }

  onTypeChange = () => {
    const currentTypeId = this.$typeRadios.filter(":checked").val().toString();
    if (currentTypeId == "comment") {
      this.$templateSelect.val("");
      this.$templateSelect.trigger("change");
    } else if (this.existTemplate(currentTypeId)) {
      this.$templateSelect.val(currentTypeId);
      this.$templateSelect.trigger("change");
    }
  };

  onTemplateChange = () => {
    const currentTypeId = this.$templateSelect.val();
    const typeTemplate = this.templates.find((template) => {
      return template.type_id == currentTypeId;
    });
    if (typeTemplate) {
      this.$messageField.text(typeTemplate.body);
    }
  };

  private existTemplate(value: string): boolean {
    return this.$templateSelect.find(`option[value='${value}']`).length > 0;
  }

  disconnect() {
    this.$typeRadios.off("change", this.onTypeChange);
    this.$templateSelect.off("change", this.onTemplateChange);
  }
}
