import ElementHelper from "./ElementHelper";

export enum NotificationType {
  Alert = 0,
  Notice = 1,
}

export class NotificationHelper {
  private $app: JQuery;

  constructor() {
    this.$app = $(".js-app");
  }

  public showNotice(notice: string): void {
    this.showNotification(notice, NotificationType.Notice);
  }

  public showAlert(notice: string): void {
    this.showNotification(notice, NotificationType.Alert);
  }

  private reserveHideNotification($notification: JQuery): void {
    setTimeout(() => {
      ElementHelper.fadeOut($notification);
    }, 3000);
  }

  private showNotification(text: string, type: NotificationType): void {
    let $notification = this.$app.find(".js-notification");
    if ($notification.length === 0) {
      $notification = this.buildNotification(text, type);
      this.$app.append($notification);
    }
    ElementHelper.fadeIn($notification);
    this.reserveHideNotification($notification);
  }

  private buildNotification(text: string, type: NotificationType): JQuery {
    let className = "";
    if (type === NotificationType.Alert) {
      className = "c-notification--alert";
    } else {
      className = "c-notification--notice";
    }
    return $("<div>", {
      class: `c-notification ${className} js-notification`,
      text,
    });
  }
}
