import { Controller } from "@hotwired/stimulus";

export default class MessagesController extends Controller {
  private messages: HTMLElement;

  connect() {
    this.messages = document.getElementById("messages");
    // scroll to latest message
    this.messages.scrollTop = this.messages.scrollHeight;
  }
}
