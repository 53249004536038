import { Controller } from "@hotwired/stimulus";
import ElementHelper from "js/util/ElementHelper";

export default class extends Controller {
  static values = {
    inputId: String,
  };

  static targets = ["detail"];

  otherCheck: HTMLInputElement;
  readonly inputIdValue!: string;
  detailTarget: HTMLDivElement;

  connect() {
    this.otherCheck = this.element.querySelector(
      `[id$='${this.inputIdValue}']`,
    );
    this.otherCheck.addEventListener("change", this.toggleDetail);
    this.toggleDetail();
  }

  toggleDetail = () => {
    if (this.otherCheck.checked) {
      ElementHelper.show(this.detailTarget);
    } else {
      ElementHelper.hide(this.detailTarget);
    }
  };

  disconnect() {
    this.otherCheck.removeEventListener("change", this.toggleDetail);
  }
}
