import { Controller } from "@hotwired/stimulus";

type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
};

export default class extends Controller {
  static targets = ["main"];

  readonly mainTarget!: HTMLElement;

  connect() {
    this.element;
  }

  hideModal() {
    this.element.remove();
  }

  clickOverlay(e: HTMLElementEvent<HTMLElement>) {
    if (e.target == this.element) {
      this.hideModal();
    }
  }

  disconnect() {
    if (this.element) {
      this.element.remove();
    }
  }
}
