import React, { HTMLAttributeAnchorTarget, MouseEvent } from "react";
import clsx from "clsx";

const colors = {
  green: "c-link--green",
};

type LinkProps = {
  href: string;
  color?: keyof typeof colors;
  target?: HTMLAttributeAnchorTarget | undefined;
  children?: React.ReactNode;
  className?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

export const Link = ({
  href,
  className,
  color,
  target,
  ...props
}: LinkProps) => {
  const css = clsx(className, colors[color]);
  const rel = target == "_blank" ? "noreferrer" : undefined;

  return (
    <a
      className={css}
      href={href}
      target={target}
      rel={rel}
      onClick={props.onClick}
    >
      {props.children}
    </a>
  );
};
