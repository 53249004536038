import React from "react";
import loader from "images/loader.svg";

export type LoaderProps = {
  show?: boolean;
};

export const Loader = ({ show = false }: LoaderProps) => {
  return (
    show && (
      <div className="c-loader" style={{ display: "block" }}>
        <img alt="" className="c-loader__img" src={loader} />
      </div>
    )
  );
};
