import error from "images/illust_500.svg";
import React from "react";

export default function AppError() {
  return (
    <div className="p-error">
      <div className="p-error__inner">
        <div className="p-error__image">
          <img src={error} alt="500" />
        </div>
        <p className="p-error__title">一時的なエラーが発生しました</p>
        <p className="p-error__text">
          エラーが発生したため表示ができませんでした、お手数ですが時間をおいて再度お試しください。
        </p>
        <a
          href="/r"
          className="p-error__button c-button c-button--primary c-button--large"
        >
          れんけーさんトップにもどる
        </a>
      </div>
    </div>
  );
}
