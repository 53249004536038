import { Controller } from "@hotwired/stimulus";

export default class ShowController extends Controller {
  private $element: JQuery<Element>;
  private $target: JQuery;
  private $trigger: JQuery;
  private hided = false;

  connect() {
    this.$element = $(this.element);
    this.$target = this.$element.find(".js-more-target");
    this.$trigger = this.$element.find(".js-more-trigger");
    this.$trigger.on("click", (e) => {
      e.preventDefault();
      this.showTarget($(e.currentTarget));
    });
    this.updateMore();
  }

  public updateMore(): void {
    this.$target.attr("style", "");
    if (this.hided) {
      return;
    }

    const targetHeight: number = this.$target.height();
    const height = this.getTargetFoldHeight();
    if (targetHeight > height + 30) {
      this.$trigger.css({ display: "block" });
      this.$target.css({ height: height, overflow: "hidden" });
    } else {
      this.$trigger.css({ display: "none" });
    }
  }

  private showTarget($trigger: JQuery): void {
    this.$target.css({ height: "auto", overflow: "" });
    $trigger.hide();
    this.hided = true;
  }

  private getTargetFoldHeight(): number {
    return this.$element.data("fold-height") as number;
  }
}
