export type CommonServerData = {
  sentry_dsn: string;
  sentry_whitelist_urls: string[];
  environment: string;
};

export class CommonServerSetting {
  public static getInstance(): CommonServerSetting {
    if (!this.instance) {
      this.instance = new CommonServerSetting();
    }

    return this.instance;
  }

  public static getSetting(): CommonServerData {
    return CommonServerSetting.getInstance().setting;
  }

  private static instance: CommonServerSetting;

  public setting: CommonServerData;

  private constructor() {
    this.setting = $(".js-setting").data("setting") as CommonServerData;
  }
}
