import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static values = { url: String };

  readonly urlValue!: string;

  connect() {
    Turbo.cache.clear();
    if (this.urlValue) {
      Turbo.visit(this.urlValue);
    }
  }
  disconnect() {
    this.element.remove();
  }
}
