import "../stylesheets/application.css.scss";
import jQuery from "jquery";
import { Turbo } from "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";
import MapPageController from "./controllers/map_page_controller";
import RedirectController from "./controllers/redirect_controller";
import ModalController from "./controllers/modal_controller";
import DropdownController from "./controllers/dropdown_controller";
import CheckboxListController from "./controllers/checkbox_list_controller";
import FormSubmitController from "./controllers/form_submit_controller";
import AxiosInterceptors from "./util/AxiosInterceptors";
import RadioToggleController from "./controllers/radio_toggle_controller";
import OtherCheckController from "./controllers/other_check_controller";
import NotificationController from "./controllers/notification_controller";
import MessageFilesController from "./controllers/message_files_controller";
import MessageDayController from "./controllers/message_day_controller";
import CheckboxToggleController from "./controllers/checkbox_toggle_controller";
import MessageFormController from "./controllers/message_form_controller";
import PrintController from "./controllers/print_controller";
import BulkCareController from "./controllers/bulk_care_controller";
import FacilityPrintController from "./controllers/facility_print_controller";
import SelectToggleController from "./controllers/select_toggle_controller";
import ShowMoreController from "./controllers/show_more_controller";
import { handleTurboError } from "./util/ErrorHandling";
import { setGaTag } from "./util/GoogleAnalytics";
import { RenkeiSentry } from "./features/setting/RenkeiSentry";
import FacilityGeocodeController from "js/controllers/facility_geocode_controller";

window.$ = jQuery;
// init sentry
RenkeiSentry.init();

window.Turbo = Turbo;
const application = Application.start();

// Configure Stimulus development experience
application.debug = document.body.dataset.env == "development";
window.Stimulus = application;
Turbo.session.setProgressBarDelay(200);
// set google analytics tag
setGaTag();
// register controller
application.register("map-page", MapPageController);
application.register("redirect", RedirectController);
application.register("modal", ModalController);
application.register("dropdown", DropdownController);
application.register("checkbox-list", CheckboxListController);
application.register("form-submit", FormSubmitController);
application.register("radio-toggle", RadioToggleController);
application.register("checkbox-toggle", CheckboxToggleController);
application.register("other-check", OtherCheckController);
application.register("notification", NotificationController);
application.register("message-files", MessageFilesController);
application.register("message-day", MessageDayController);
application.register("message-form", MessageFormController);
application.register("print", PrintController);
application.register("bulk-care", BulkCareController);
application.register("facility-print", FacilityPrintController);
application.register("select-toggle", SelectToggleController);
application.register("show-more", ShowMoreController);
application.register("facility-geocode", FacilityGeocodeController);

AxiosInterceptors.addResponse();
handleTurboError();
