import React from "react";
import clsx from "clsx";

export type ModalBodyProps = {
  children: React.ReactNode;
  className?: string;
};

export const ModalBody = (props: ModalBodyProps) => {
  const css = clsx("c-modal__body", props.className);

  return <div className={css}>{props.children}</div>;
};
