import React from "react";
import { Marker } from "@react-google-maps/api";
import { Facility } from "js/pages/map/types";

type Props = {
  facility: Facility;
  icon: google.maps.Icon;
  label: string;
};

export const FacilityMarker = (props: Props) => {
  const { facility } = props;

  const getLabel = () => {
    return {
      className: "p-map-marker__text",
      text: props.label,
    };
  };

  return (
    <Marker
      key={facility.id}
      position={{
        lat: Number(facility.latitude),
        lng: Number(facility.longitude),
      }}
      icon={props.icon}
      label={getLabel()}
    />
  );
};
