import { Controller } from "@hotwired/stimulus";

export default class BulkCareController extends Controller {
  private $element: JQuery<Element>;
  private $bulkRadios: JQuery;
  private $targetCares: JQuery;
  private $targetRadios: JQuery;

  connect() {
    this.$element = $(this.element);
    this.$bulkRadios = this.$element.find(".js-bulk-radio");
    this.$bulkRadios.on("change", this.onBulkRadioChange);
    this.$targetCares = this.$element.find(".js-target-cares");
    this.$targetRadios = this.$targetCares.find("input[type='radio']");
    this.$targetRadios.on("change", this.onTargetRadioChange);
    this.updateBulkRadio();
  }

  onBulkRadioChange = () => {
    const careValue = this.$bulkRadios.filter(":checked").val().toString();
    this.$targetCares.each((index, ele) => {
      const $careLine = $(ele);
      const $radio = $careLine.find(
        `input[type="radio"][value="${careValue}"]`,
      );
      $radio.prop("checked", true);
    });
  };

  onTargetRadioChange = () => {
    this.updateBulkRadio();
  };

  private updateBulkRadio(): void {
    const values = this.$targetCares.map((index, ele) => {
      const $radios = $(ele);
      return $radios
        .find("input[type='radio']")
        .filter(":checked")
        .val()
        .toString();
    });
    const uniqCareValues = [...new Set(values)];
    if (uniqCareValues.length == 1) {
      this.$bulkRadios
        .filter(`[value="${uniqCareValues[0]}"]`)
        .prop("checked", true);
    } else {
      this.$bulkRadios.filter(":checked").prop("checked", false);
    }
  }

  disconnect() {
    this.$bulkRadios.off("change", this.onBulkRadioChange);
    this.$targetRadios.off("change", this.onTargetRadioChange);
  }
}
