import React from "react";
import clsx from "clsx";

const colors = {
  gray200: "c-label--gray200",
  gray300: "c-label--gray300",
  gray700: "c-label--gray700",
  green500: "c-label--green500",
};

const sizes = {
  sm: "c-label--small",
  md: "",
  lg: "c-label--large",
};

const shapes = {
  round: "c-label--round",
};

export type LabelProps = {
  color: keyof typeof colors;
  size?: keyof typeof sizes;
  shape?: keyof typeof shapes;
  children?: React.ReactNode;
  className?: string;
};

export const Label = ({
  color,
  size = "md",
  className,
  shape,
  ...props
}: LabelProps) => {
  const css = clsx(
    "c-label",
    colors[color],
    sizes[size],
    shapes[shape],
    className,
  );

  return <label className={css}>{props.children}</label>;
};
