import React from "react";
import { Facility } from "js/pages/map/types";
import { FacilityPrint } from "./FacilityPrint";

type PrintContentProps = {
  facilities: Facility[];
  google_map_api_key: string;
};

const PrintContent = React.forwardRef<HTMLDivElement, PrintContentProps>(
  (props, ref) => {
    return (
      <div ref={ref}>
        <FacilityPrint
          facilities={props.facilities}
          google_map_api_key={props.google_map_api_key}
        />
      </div>
    );
  },
);

PrintContent.displayName = "PrintContent";

export { PrintContent, PrintContentProps };
