import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Loader } from "js/components/Elements";
import { ConditionSummary, Facility } from "js/pages/map/types";
import redPin from "images/icon/map-pin-red-2.svg";
import bluePin from "images/icon/map-pin-blue.svg";
import FacilityHelper from "js/pages/map/helper/FacilityHelper";
import { DateHelper } from "js/util/DateHelper";
import { ConditionDetail } from "./ConditionDetail";
import { FacilityMarker } from "./FacilityMarker";

type FacilityPrintProps = {
  facilities: Facility[];
  google_map_api_key: string;
  condition_summary?: ConditionSummary;
};

export const FacilityPrint = (props: FacilityPrintProps) => {
  const facilities = props.facilities.slice(0, 100);
  const summary = props.condition_summary;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: props.google_map_api_key,
  });

  const [googleMap, setGoogleMap] = useState<google.maps.Map>(null);
  const onGoogleMapLoad = useCallback(
    (map: google.maps.Map) => setGoogleMap(map),
    [],
  );

  const mapRef = useRef<GoogleMap>(null);

  // 施設を全部含めるようにbounds調整する
  useEffect(() => {
    const fitBounds = () => {
      if (googleMap) {
        const bounds = new google.maps.LatLngBounds();
        facilities.forEach((fa) => {
          bounds.extend(new google.maps.LatLng(fa.latitude, fa.longitude));
        });
        googleMap.fitBounds(bounds);
      }
    };
    fitBounds();
  }, [facilities, googleMap]);

  const jigyoshoFacilities = facilities.filter((facility) => {
    return FacilityHelper.isJigyosho(facility);
  });

  const medicalKaigoFacilities = facilities.filter((facility) => {
    return !FacilityHelper.isJigyosho(facility);
  });

  if (isLoaded) {
    return (
      <div className="c-facility-print">
        <div className="c-facility-print__head">
          <p className="c-facility-print__head__title">おすすめリスト</p>
          <span>※{DateHelper.toString(new Date())}時点</span>
        </div>
        {summary && (
          <div className="c-facility-print__top">
            <ConditionDetail value={summary?.area_full} label={"中心点"} />
            <ConditionDetail
              value={summary?.facility_type}
              label={"検索対象"}
            />
            {/* 病院 */}
            <ConditionDetail
              value={summary?.medical?.vacancy_status}
              label={"空床状況"}
            />
            <ConditionDetail
              value={summary?.medical?.vacancy_room}
              label={"空床タイプ"}
            />
            <ConditionDetail
              value={summary?.medical?.division}
              label={"病棟・病床区分"}
            />
            <ConditionDetail
              value={summary?.medical?.department}
              label={"診療科目"}
            />
            <ConditionDetail
              value={summary?.medical?.medical_care}
              label={"入院受け入れ可能要件"}
            />
            {/* 介護施設 */}
            <ConditionDetail
              value={summary?.jigyosho?.start_live_cost}
              label={"入居一時金"}
            />
            <ConditionDetail
              value={summary?.jigyosho?.month_cost}
              label={"月額費用"}
            />
            <ConditionDetail
              value={summary?.jigyosho?.accept_tag}
              label={"入居条件"}
            />
            <ConditionDetail value={summary?.lists} label={"リスト"} />
          </div>
        )}
        <GoogleMap
          ref={mapRef}
          onLoad={onGoogleMapLoad}
          mapContainerClassName="c-facility-print__map"
          zoom={12}
        >
          {medicalKaigoFacilities &&
            medicalKaigoFacilities.map((facility, index) => (
              <FacilityMarker
                key={facility.id}
                facility={facility}
                icon={{
                  url: redPin,
                  scaledSize: new google.maps.Size(40, 40),
                }}
                label={(index + 1).toString()}
              />
            ))}
          {jigyoshoFacilities &&
            jigyoshoFacilities.map((facility, index) => (
              <FacilityMarker
                key={facility.id}
                facility={facility}
                icon={{
                  url: bluePin,
                  scaledSize: new google.maps.Size(40, 40),
                }}
                label={(index + 1).toString()}
              />
            ))}
        </GoogleMap>
        {/* 病院など */}
        {medicalKaigoFacilities.length > 0 && (
          <p className="c-facility-print__caption">医療機関等</p>
        )}
        {medicalKaigoFacilities.length > 0 && (
          <table className="c-facility-print__table">
            <thead>
              <tr>
                <th>No,</th>
                <th>施設名</th>
                <th>住所</th>
                <th>電話番号</th>
              </tr>
            </thead>
            <tbody>
              {medicalKaigoFacilities.map((facility, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="u-fw-bold">{facility.name}</td>
                  <td>{facility.address}</td>
                  <td>{facility.tel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {/* 介護施設 */}
        {jigyoshoFacilities.length > 0 && (
          <p className="c-facility-print__caption">介護施設（青ピン）</p>
        )}
        {jigyoshoFacilities.length > 0 && (
          <React.Fragment>
            <table className="c-facility-print__table">
              <thead>
                <tr>
                  <th>No,</th>
                  <th>施設名</th>
                  <th>住所</th>
                  <th>入居一時金</th>
                  <th>月額費用</th>
                </tr>
              </thead>
              <tbody>
                {jigyoshoFacilities.map((facility, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="u-fw-bold">{facility.name}</td>
                    <td>{facility.address}</td>
                    <td>{facility.jigyosho?.start_live_cost || "-"}</td>
                    <td>{facility.jigyosho?.month_cost || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="c-facility-print__bottom">
              <p className="c-facility-print__bottom__title">
                空室確認前の情報になります。最新の空室情報や介護施設の入居相談は下記までお問い合わせください。
              </p>
              <div className="c-facility-print__bottom__row">
                <div className="c-facility-print__dial" />
                <span className="c-facility-print__bottom__tel">
                  0120-520-031
                </span>
                <span className="c-facility-print__bottom__desc">
                  無料入居相談室9:00〜18:00
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="c-facility-print__logo" />
      </div>
    );
  } else {
    return <Loader show={true} />;
  }
};
