import React, { useRef } from "react";
import { useSpring, animated } from "react-spring";
import clsx from "clsx";
import { useClickOutside } from "js/hooks";

export type SideSlideProps = {
  toggle?: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  open?: boolean | null;
  className?: string;
};

export const SideSlide = ({
  open = null,
  className,
  ...props
}: SideSlideProps) => {
  const css = clsx("c-side-slide", className);

  const divRef = useRef<HTMLDivElement>(null);
  useClickOutside(open, divRef, props.onClose);

  const styles = useSpring({ right: open ? 0 : -428 });

  return (
    <div ref={divRef}>
      {props.toggle && props.toggle}
      {open != null && (
        <animated.div className={css} style={styles}>
          {props.children}
        </animated.div>
      )}
    </div>
  );
};
