import React, { MouseEvent, useEffect, useState } from "react";
import clsx from "clsx";
import { useSpring, animated } from "react-spring";

export type ModalProps = {
  children: React.ReactNode;
  onClose: () => void;
  open?: boolean | null;
  className?: string;
  overModal?: boolean; // modalの上に重なる
};

export const Modal = ({
  open = null,
  className,
  overModal = false,
  ...props
}: ModalProps) => {
  const modalCss = clsx("c-modal", overModal && "c-modal--over");
  const css = clsx("c-modal__main c-modal--sm", className);

  const onMainClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  const onModalClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    props.onClose();
  };

  const [frame, setFrame] = useState<boolean>(false);

  const [styles, api] = useSpring(() => ({
    opacity: 0,
    config: { duration: 350 },
  }));

  useEffect(() => {
    if (open == true) {
      setFrame(true);
    }
    void api.start({
      opacity: open ? 1 : 0,
      onRest: () => {
        if (open == false) {
          setFrame(open);
        }
      },
    });
  }, [open]);

  return (
    open != null && (
      <div
        className={modalCss}
        onClick={onModalClick}
        style={{ display: frame ? "flex" : "none" }}
      >
        <animated.div className={css} onClick={onMainClick} style={styles}>
          {props.children}
        </animated.div>
      </div>
    )
  );
};
