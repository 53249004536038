import { Controller } from "@hotwired/stimulus";

type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
};

export default class extends Controller {
  static targets = [
    "checkboxAll",
    "checkbox",
    "showChecked",
    "hideChecked",
    "input",
  ];

  hasCheckboxAllTarget: boolean;
  hasHideCheckedTarget: boolean;
  hasShowCheckedTarget: boolean;
  checkboxTargets: HTMLInputElement[];
  checkboxAllTarget: HTMLInputElement;
  hideCheckedTarget: HTMLInputElement;
  showCheckedTarget: HTMLInputElement;
  inputTargets: HTMLInputElement[];

  connect() {
    this.checkboxAllTarget.addEventListener("change", this.toggle);
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.addEventListener("change", this.toggleItem),
    );
    this.refresh();
  }

  checkAll = (e: HTMLElementEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.checkboxAllTarget.checked = true;
    this.toggle();
  };

  toggleForm = () => {
    if (
      this.checkboxAllTarget.checked ||
      this.checkboxAllTarget.indeterminate
    ) {
      if (this.hasHideCheckedTarget) {
        this.hideCheckedTarget.style.display = "none";
      }
      if (this.hasShowCheckedTarget) {
        if (this.showCheckedTarget.tagName === "TR") {
          this.showCheckedTarget.style.display = "table-row";
        } else {
          this.showCheckedTarget.style.display = "block";
        }
      }
    } else {
      if (this.hasHideCheckedTarget) {
        if (this.hideCheckedTarget.tagName === "TR") {
          this.hideCheckedTarget.style.display = "table-row";
        } else {
          this.hideCheckedTarget.style.display = "block";
        }
      }
      if (this.hasShowCheckedTarget) {
        this.showCheckedTarget.style.display = "none";
      }
    }
    // TODO inputタグにidsを入れる処理
    this.inputTargets.forEach((element, index) => {
      if (index === 0) {
        return;
      }
      element.remove();
    });

    const input = this.inputTargets[0];
    if (this.checkedIds.length === 0) {
      input.value = "";
    } else {
      this.checkedIds.forEach((id, index) => {
        if (index === 0) {
          input.value = id;
          return;
        }
        const new_input: HTMLInputElement = <HTMLInputElement>input.cloneNode();
        new_input.value = id;
        input.after(new_input);
      });
    }
  };

  toggle = () => {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.checkboxAllTarget.checked;
    });
    this.toggleForm();
  };

  toggleItem = () => {
    this.refresh();
  };

  refresh() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked,
    ).length;

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
    this.checkboxAllTarget.indeterminate =
      checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;
    this.toggleForm();
  }

  triggerInputEvent(checkbox: HTMLInputElement): void {
    const event = new Event("input", { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }

  get checkedIds() {
    return this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
  }

  disconnect() {
    this.checkboxAllTarget.removeEventListener("change", this.toggle);
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.removeEventListener("change", this.toggleItem),
    );
  }
}
