import { Controller } from "@hotwired/stimulus";

export default class FacilityGeocodeController extends Controller {
  private $element: JQuery<Element>;
  private $map: JQuery;
  private $latField: JQuery;
  private $lonField: JQuery;
  private map: google.maps.Map;

  connect() {
    this.$element = $(this.element);
    this.$map = this.$element.find("#facility-map");
    this.$latField = this.$element.find(".js-latitude");
    this.$lonField = this.$element.find(".js-longitude");
    this.initMap();
    this.initMarker();
  }

  private initMap(): void {
    const center = {
      lat: Number(this.$map.data("lat")),
      lng: Number(this.$map.data("lon")),
    };
    this.map = new google.maps.Map(document.getElementById("facility-map"), {
      draggable: true,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      zoom: 15,
      center: center,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
      },
    });
  }

  private initMarker(): void {
    const marker = new google.maps.Marker({
      position: this.map.getCenter(),
      map: this.map,
      draggable: true,
    });

    marker.addListener("dragend", (argument: google.maps.MapMouseEvent) => {
      this.$latField.val(argument.latLng.lat());
      this.$lonField.val(argument.latLng.lng());
    });
  }
}
