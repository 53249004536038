import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  private $element: JQuery<Element>;
  private $select: JQuery;
  private $targets: JQuery;

  connect() {
    this.$element = $(this.element);
    this.$select = this.$element.find(".js-toggle-select");
    this.$targets = this.$element.find(".js-toggle-targets");
    this.$select.on("change", this.toggleTarget);
  }

  toggleTarget = () => {
    this.$targets.each((index, ele) => {
      if (index === Number(this.$select.val())) {
        $(ele).show();
      } else {
        $(ele).hide();
      }
    });
    this.dispatch("afterToggle");
  };
}
