import { Controller } from "@hotwired/stimulus";
import React from "react";
import { Root, createRoot } from "react-dom/client";
import {
  PrintButton,
  PrintButtonProps,
} from "js/features/facility_print/PrintButton";

export default class extends Controller {
  private $element: JQuery<Element>;
  private root: Root;

  connect() {
    const container = this.element;
    this.root = createRoot(container);
    this.$element = $(this.element);
    const printJson = this.$element.data("print-value") as PrintButtonProps;
    this.root.render(<PrintButton {...printJson} />);
  }

  disconnect() {
    this.root.unmount();
    this.root = null;
  }
}
