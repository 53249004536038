import * as Sentry from "@sentry/browser";
import { CommonServerSetting } from "./CommonServerSetting";

export class RenkeiSentry {
  public static init(): void {
    this.initSentry();
  }

  public static initSentry(): void {
    const setting = CommonServerSetting.getSetting();
    if (setting.sentry_dsn) {
      Sentry.init({
        dsn: setting.sentry_dsn,
        allowUrls: setting.sentry_whitelist_urls,
        environment: setting.environment,
      });
    }
  }
}
