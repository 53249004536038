import { Controller } from "@hotwired/stimulus";
import { createPopper, Instance, Placement } from "@popperjs/core";

type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
};

export default class extends Controller {
  static targets = ["menu"];

  private $element: JQuery<Element>;
  private popperInstance: Instance;
  private hidden: boolean;
  readonly menuTarget!: HTMLElement;

  connect() {
    this.$element = $(this.element);
    this.popperInstance = createPopper(this.element, this.menuTarget, {
      placement: this.getPlacement(),
    });

    this.hide();
  }

  toggle(event: HTMLElementEvent<HTMLElement>) {
    event.preventDefault();
    if (this.hidden) {
      this.show();
    } else {
      this.hide();
    }
  }

  public clickOverlay = (event: HTMLElementEvent<HTMLElement>) => {
    if (this.element.contains(event.target)) {
      return;
    }
    this.hide();
  };

  show() {
    this.hidden = false;
    this.menuTarget.removeAttribute("hidden");
    if (this.popperInstance) {
      void this.popperInstance.update();
    }
    document.addEventListener("click", this.clickOverlay);
  }

  hide() {
    this.hidden = true;
    this.menuTarget.setAttribute("hidden", "");
    document.removeEventListener("click", this.clickOverlay);
  }

  private getPlacement(): Placement {
    const placement = this.$element.data("placement") as Placement;
    if (placement) {
      return placement;
    }
    return "bottom-start";
  }

  disconnect() {
    this.hide();
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}
