import React, {
  CSSProperties,
  ButtonHTMLAttributes,
  MouseEvent,
  HTMLAttributeAnchorTarget,
} from "react";
import clsx from "clsx";
import { IconProps, Icon } from "../Icon";

const colors = {
  primary: "c-button--primary",
  secondary: "c-button--secondary",
  green: "c-button--green",
  white: "c-button--white",
  gray: "c-button--gray",
  black: "c-button--black",
};

const sizes = {
  sm: "c-button--small",
  md: "",
  lg: "c-button--large",
};

type ButtonProps = {
  color?: keyof typeof colors;
  size?: keyof typeof sizes;
  children?: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
  css?: CSSProperties;
  href?: string;
  icon?: IconProps;
  disabled?: boolean;
  target?: HTMLAttributeAnchorTarget;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

export const Button = ({
  color = "primary",
  size = "md",
  className,
  icon,
  ...props
}: ButtonProps) => {
  const css = clsx(
    "c-button",
    colors[color],
    sizes[size],
    props.disabled && "disabled",
    className,
  );

  if (props.href) {
    return (
      <a
        className={css}
        style={props.css}
        href={props.href}
        onClick={props.onClick}
        target={props.target}
      >
        {props.children}
      </a>
    );
  }

  let iconCss = "";
  if (icon) {
    iconCss = clsx("c-button__icon", icon.className);
  }

  return (
    <button className={css} style={props.css} onClick={props.onClick}>
      {icon && <Icon {...icon} className={iconCss} />}
      {props.children}
    </button>
  );
};
