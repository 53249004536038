import { NotificationHelper } from "js/util/notification_helper";

export const handleTurboError = () => {
  /* eslint-disable */
  document.addEventListener("turbo:before-fetch-response", (event: any) => {
    const response = event.detail.fetchResponse.response as Response;
    if (!response.ok && response.status != 422) {
      const notification = new NotificationHelper();
      notification.showAlert(
        "エラーが発生しました、お手数ですが時間をおいて再度お試しください。",
      );
    }
  });
};
