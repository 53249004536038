import React from "react";

export type ModalHeadProps = {
  children: React.ReactNode;
  onClose: () => void;
};

export const ModalHead = (props: ModalHeadProps) => {
  return (
    <div className="c-modal__head">
      <div className="c-modal__title">{props.children}</div>
      <div className="c-modal__closer" onClick={props.onClose} />
    </div>
  );
};
